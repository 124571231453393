import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import ImgComponent from "../content/imgComponent"
import React from 'react'; 
const AccreditationSlider = ({ contents, blogPostCls, imgEffect }) => {
  const accreditations = [
    {
      id: 1,
      // name: "John Smith",
      testimony: "GLP Accreditation ISO 17025",
      image: "quality_kan.jpg",
      //   company:
      //     "The National Agency of Drug and Food Control Jakarta: BPOM RI. 2016.",
    },
    {
      id: 2,
      // name: "John Smith",
      testimony: "Guideline for Good Clinical Practice E6 (R2)",
      image: "quality_npra.jpg",
      //   company:
      //     "ICH Expert Working Group. ICH Harmonised Tripartite Guideline, 2016.",
    },
    {
      id: 3,
      // name: "John Smith",
      testimony: "Good Laboratory Practice Principles",
      image: "quality_ujiklinik.jpg",
      //   company:
      //     "(ISO/IEC 17025)/GLP General requirements for the competence of testing and calibration laboratories, 2017.",
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      <Slider {...settings} className="slider-content btn-style-1">
        {accreditations.map((content) => (
          <div className="item">
            <div className="item-inner">
              <div className="">
                {/* <StaticImage
                  width={1500} height={1500}
                  src="../../../images/quality_ujiklinik.jpg"
                //   className="rounded-image"
                /> */}
                {/* <img src={content.image}></img> */}
                <ImgComponent
                  pmlImg={content.image}
                  // className="rounded-image"
                />
              </div>
              <div className="item-inner-content">
                <div style={{ minHeight: "150px" }}>
                  {/* <p className="content-testimony">{content.testimony}</p> */}
                  <h4 className="content-name">{content.testimony}</h4>
                  <h4 className="content-company">{content.company}</h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default AccreditationSlider
