import React from "react"

const Experience = () => {
  return (
    <section
      className="elementor-element elementor-element-3523193 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id={3523193}
      data-element_type="section"
    >
      <center>
        <div
          className="elementor-container elementor-column-gap-default padding-bawah-experience"
          id="test"
        >
          <div className="elementor-row">
            <div
              className="elementor-element elementor-element-2d21af3 elementor-column elementor-col-33 elementor-top-column"
              data-id="2d21af3"
              data-element_type="column"
              data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
            >
              <div
                className="elementor-column-wrap elementor-element-populated"
                id="experience-width-phone"
              >
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-6f3f9ee elementor-widget elementor-widget-counter"
                    data-id="6f3f9ee"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                    data-widget_type="counter.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-counter">
                        <div className="elementor-counter-number-wrapper">
                          <span className="elementor-counter-number-prefix" />
                          <span
                            className="elementor-counter-number font-merah"
                            data-duration={1000}
                            data-to-value={3}
                            data-from-value={0}
                            data-delimiter=","
                          >
                            +400
                          </span>
                          <span className="elementor-counter-number-suffix" />
                        </div>
                        <div
                          className="elementor-counter-title"
                          id="font-size-experience"
                        >
                          Happy Clients
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-03a6f88 elementor-column elementor-col-33 elementor-top-column"
              data-id="03a6f88"
              data-element_type="column"
              data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
            >
              <div
                className="elementor-column-wrap elementor-element-populated"
                id="experience-width-phone"
              >
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-8945cbc elementor-widget elementor-widget-counter"
                    data-id="8945cbc"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                    data-widget_type="counter.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-counter">
                        <div className="elementor-counter-number-wrapper">
                          <span className="elementor-counter-number-prefix" />
                          <span
                            className="elementor-counter-number font-merah"
                            data-duration={1000}
                            data-to-value="1.5"
                            data-from-value={0}
                            data-delimiter=","
                          >
                            +15
                          </span>
                          <span className="elementor-counter-number-suffix" />
                        </div>
                        <div
                          className="elementor-counter-title"
                          id="font-size-experience"
                        >
                          YEARS EXPERIENCE
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-8e783a0 elementor-column elementor-col-33 elementor-top-column"
              data-id="8e783a0"
              data-element_type="column"
              data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
            >
              <div
                className="elementor-column-wrap elementor-element-populated"
                id="experience-width-phone"
              >
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-993abd3 elementor-widget elementor-widget-counter"
                    data-id="993abd3"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                    data-widget_type="counter.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-counter">
                        <div className="elementor-counter-number-wrapper">
                          <span className="elementor-counter-number-prefix" />
                          <span
                            className="elementor-counter-number  font-merah"
                            data-duration={1000}
                            data-to-value={45}
                            data-from-value={0}
                            data-delimiter=","
                          >
                            +1700
                          </span>
                          <span className="elementor-counter-number-suffix" />
                        </div>
                        <div
                          className="elementor-counter-title"
                          id="font-size-experience"
                        >
                          COMPLETE PROJECTS
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </center>
      <br />
      <div
        className="elementor-element elementor-element-e1304d7 elementor-widget__width-inherit elementor-widget elementor-widget-divider"
        data-id="e1304d7"
        data-element_type="widget"
        data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0,"sizes":[]},"":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
        data-widget_type="divider.default"
      >
        <div className="elementor-widget-container">
          <div className="elementor-divider">
            <span className="elementor-divider-separator" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience
