// import img01 from '../../../../upload/banner_aboutus.jpg';
import ImgComponent from "../imgComponent"
import React from "react"
const Banner = () => {
  return (
    <section
      className="elementor-element elementor-element-fb7667e elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched","shape_divider_bottom":"curve","shape_divider_bottom_negative":"yes"}'
    >
      <div
        className="elementor-shape elementor-shape-bottom"
        data-negative="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
          />
        </svg>
      </div>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-badcbdc elementor-column elementor-col-100 elementor-top-column"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-7bef6e7 elementor-widget elementor-widget-avante-slider-parallax"
                  data-element_type="widget"
                  data-widget_type="avante-slider-parallax.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="slider-parallax-wrapper"
                      data-autoplay={8000}
                      data-pagination={0}
                      data-navigation={1}
                    >
                      <div className="slider_parallax_inner">
                        <div className="slider_parallax_slides">
                          <div className="slide is-active ">
                            <div className="slide-content align-center">
                              <div className="standard-wrapper">
                                <div className="caption padding-banner-aboutus">
                                  <div
                                    className="elementor-widget-container"
                                    id="font-empower"
                                  >
                                    {/* EMPOWERING YOUR BUSINESS */}
                                  </div>

                                  <div className="title title-banner padding-title-banner">
                                    <h2>
                                      Pharma Metric Labs <br></br>is Your
                                      Partner
                                    </h2>
                                  </div>
                                  <div className="text">
                                    We give you our best end-to-end services
                                    with our years of experiences
                                  </div>
                                  <a
                                    className="button"
                                    id="warnaButton"
                                    href="/HelpCenter/ContactUs"
                                  >
                                    Get In Touch{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="image-container">
                              <ImgComponent
                                pmlImg="banner_aboutus.jpg"
                                className="image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner

// import React from 'react';
// import img1 from '../../upload/red_bubble.png';
// import img2 from '../../upload/red_bubble.png';
// import img3 from '../../upload/navy_bubble.png';
// import img4 from '../../upload/navy_bubble.png';
// import img5 from '../../upload/navy_triangle.png';
// import img6 from '../../upload/red_triangle2.png';

// // import imgbanner from '../../../../upload/banner_aboutus.png';

// const Banner = () => {
// 	return (
// 		<section
// 			className="elementor-element elementor-element-5ba384c elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
// 			data-id="5ba384c"
// 			data-element_type="section"
// 			data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_bottom&quot;:&quot;book&quot;}"
// 		>
// 			<div className="elementor-shape elementor-shape-bottom" data-negative="true">
// 				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
// 					<path
// 						className="elementor-shape-fill"
// 						d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
// 					/>
// 				</svg>
// 			</div>
// 			<div className="elementor-container elementor-column-gap-default">
// 				<div className="elementor-row">
// 					<div
// 						className="elementor-element elementor-element-f3a72b3 elementor-column elementor-col-100 elementor-top-column"
// 						data-id="f3a72b3"
// 						data-element_type="column"
// 						data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 					>
// 						<div className="elementor-column-wrap elementor-element-populated">
// 							<div className="elementor-widget-wrap">
// 								<div
// 									className="elementor-element elementor-element-a12d681 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="a12d681"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_infinite&quot;:&quot;true&quot;,&quot;avante_ext_infinite_animation&quot;:&quot;if_scale&quot;,&quot;avante_ext_infinite_duration&quot;:3,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_infinite_easing&quot;:&quot;0.250, 0.250, 0.750, 0.750&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={84}
// 												height={84}
// 												src={img1}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-5a368d6 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="5a368d6"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_infinite&quot;:&quot;true&quot;,&quot;avante_ext_infinite_animation&quot;:&quot;if_scale&quot;,&quot;avante_ext_infinite_duration&quot;:3,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_infinite_easing&quot;:&quot;0.250, 0.250, 0.750, 0.750&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={84}
// 												height={84}
// 												src={img2}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-d60c0f0 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="d60c0f0"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_infinite&quot;:&quot;true&quot;,&quot;avante_ext_infinite_duration&quot;:3,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_infinite_animation&quot;:&quot;if_bounce&quot;,&quot;avante_ext_infinite_easing&quot;:&quot;0.250, 0.250, 0.750, 0.750&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={108}
// 												height={108}
// 												src={img3}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-5ba5845 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="5ba5845"
// 									data-element_type="widget"
// 									data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_disable&quot;:&quot;tablet&quot;,&quot;avante_ext_scrollme_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-100,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-50,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_smoothness&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:30,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scalez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={108}
// 												height={108}
// 												src={img4}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-56ced69 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="56ced69"
// 									data-element_type="widget"
// 									data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_disable&quot;:&quot;tablet&quot;,&quot;avante_ext_scrollme_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-100,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-50,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_smoothness&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:30,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scalez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={108}
// 												height={108}
// 												src={img4}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-9432a43 elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="9432a43"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:300,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]},&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_smoothness&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-360,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scalez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={190}
// 												height={150}
// 												src={img5}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-7fce836 elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="7fce836"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:120,&quot;sizes&quot;:[]},&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_smoothness&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-360,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scalez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={150}
// 												height={120}
// 												src={img6}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-fce7c7a elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="fce7c7a"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:180,&quot;sizes&quot;:[]},&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_smoothness&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-360,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:100,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_scalez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_scrollme_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={150}
// 												height={120}
// 												src={img6}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-6da3030 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
// 									data-id="6da3030"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_infinite&quot;:&quot;true&quot;,&quot;avante_ext_infinite_animation&quot;:&quot;if_scale&quot;,&quot;avante_ext_infinite_duration&quot;:3,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_infinite_easing&quot;:&quot;0.250, 0.250, 0.750, 0.750&quot;}"
// 									data-widget_type="image.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-image">
// 											<img
// 												width={84}
// 												height={84}
// 												src={img1}
// 												className="attachment-large size-large"
// 												alt=""
// 											/>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-1b3eec1 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading"
// 									data-id="1b3eec1"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 									data-widget_type="heading.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<h2 className="elementor-heading-title elementor-size-default">
// 											empower your business
// 										</h2>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-2de528b elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
// 									data-id="2de528b"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 									data-widget_type="heading.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<h1 className="elementor-heading-title elementor-size-default">
// 										<center>
// 										Pharma Metric Labs<br />
// 										is Your Partner</center>
// 										</h1>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-dd02d3d elementor-widget elementor-widget-text-editor"
// 									data-id="dd02d3d"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 									data-widget_type="text-editor.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-text-editor elementor-clearfix">
// 											<div className="lqd-lines split-unit lqd-unit-animation-done">
// 											With high performing and experienced team, we ensure a high quality result
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-9dfae0d elementor-align-center elementor-mobile-align-center elementor-widget elementor-widget-button"
// 									data-id="9dfae0d"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 									data-widget_type="button.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-button-wrapper">
// 											<a
// 												href="#"
// 												className="elementor-button-link elementor-button elementor-size-md"
// 												role="button"
// 											>
// 												<span className="elementor-button-content-wrapper">
// 													<span className="elementor-button-icon elementor-align-icon-right">
// 														<i aria-hidden="true" className="fas fa-long-arrow-alt-right" />
// 													</span>
// 													<span className="elementor-button-text">Get In Touch</span>
// 												</span>
// 											</a>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</section>
// 	);
// };

// export default Banner;
