import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import ImgComponent from "../content/imgComponent"
import React from 'react'; 

const PartnersSlider = ({ contents, blogPostCls, imgEffect }) => {
  const partners = [
    {
      id: 1,
      // name: "John Smith",
      testimony: "Pedoman Cara Uji Klinik yang Baik",
      image: "quality_ujiklinik.jpg",
      company:
        "The National Agency of Drug and Food Control Jakarta: BPOM RI. 2016.",
    },
    {
      id: 2,
      // name: "John Smith",
      testimony: "Guideline for Good Clinical Practice E6 (R2)",
      image: "quality_guideline_good_clinical.jpg",
      company:
        "ICH Expert Working Group. ICH Harmonised Tripartite Guideline, 2016.",
    },
    {
      id: 3,
      // name: "John Smith",
      testimony: "Good Laboratory Practice Principles",
      image: "quality_good_laboratory.jpg",
      company:
        "(ISO/IEC 17025)/GLP General requirements for the competence of testing and calibration laboratories, 2017.",
    },
    {
      id: 4,
      // name: "John Smith",
      testimony: "Guideline on the Investigation of Bioequivalence",
      image: "quality_guidline_investigation.jpg",
      company:
        "European Medicines Agency, Doc. Ref. CPMP/EWP/QWP/1401/98 Rev. 1. 2010.",
    },
    {
      id: 5,
      // name: "John Smith",
      testimony:
        "Ethical Principles for Medical Research Involving Human Subjects",
      image: "quality_ethical_principles.jpg",
      company:
        "World Medical Association Declaration Of Helsinki Scotland: 52nd WMA General Assembly, 2000.",
    },
    {
      id: 6,
      // name: "John Smith",
      testimony: "Guidance for Industry Bio Analytical Method Validation",
      image: "quality_guidance.jpg",
      company:
        "U.S. Department of Health and Human Services, Food and Drug Administration, Center for Drug Evaluation and Research (CDER), Center for Veterinary Medicine (CVM), May 2001.",
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      <Slider {...settings} className="slider-content btn-style-1">
        {partners.map((content) => (
          <div className="item">
            <div className="item-inner">
              <div className="">
                {/* <StaticImage
                  src="../../../images/quality_guidance.jpg"
                //   className="rounded-image"
                /> */}
                <ImgComponent
                  pmlImg={content.image}
                  // className="rounded-image"
                />
              </div>
              <div className="item-inner-content">
                <div style={{ minHeight: "150px" }}>
                  {/* <p className="content-testimony">{content.testimony}</p> */}
                  <h4 className="content-name">{content.testimony}</h4>
                  <h4 className="content-company">{content.company}</h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default PartnersSlider
